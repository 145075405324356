<!--
 * @Descripttion: 注册
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2020-12-30 14:12:36
-->
<template>
    <div class="pad">
        <form class="login">
            <p>公司名称<span class="red">*</span></p>
            <input type="text" required placeholder="请输入" v-model="org.departName">
            <p>统一社会信用代码<span class="red">*</span></p>
            <input type="text" required placeholder="请输入" v-model="org.usccCode" maxlength='18'>
            <p>联系人姓名</p>
            <input type="text" required placeholder="请输入" v-model="org.legalPerson">
            <p>联系电话</p>
            <input type="tel"  required placeholder="请输入" v-model="org.mobile" maxlength='11'>
            <!-- <p>上传营业执照（副本）</p>
            <div class="bg-upload h3 b3 w4 flex-col">
                <van-uploader :after-read="afterRead" v-model="fileList1" max-count="1" class="center"
                    accept="image/*" :before-read="beforeRead" :max-size="10 * 1024 * 1024"
                     @oversize="onOversize" :preview-full-image="false">
                     <i class="icon-upload"></i><p>营业执照</p>
                </van-uploader>
            </div> -->
            <!-- <p>上传法定代表人身份证</p>
            <div class="flex-normal">
                <div class="bg-upload h3 b3 w4 flex-col">
                <van-uploader :after-read="afterRead" v-model="fileList2" max-count="1" class="center"
                    accept="image/*" :before-read="beforeRead" :max-size="10 * 1024 * 1024"
                     @oversize="onOversize" :preview-full-image="false">
                    <i class="icon-upload"></i>
                    <p>身份证正面</p>
                 </van-uploader>
                </div>
                <div class="bg-upload h3 b3 w4 flex-col mar-l">
                <van-uploader :after-read="afterRead" v-model="fileList3" max-count="1" class="center"
                    accept="image/*" :before-read="beforeRead" :max-size="10 * 1024 * 1024"
                     @oversize="onOversize" :preview-full-image="false">
                    <i class="icon-upload"></i>
                    <p>身份证背面</p>
                </van-uploader>
                </div>
            </div> -->

            <p class="center flex mar-t">
                <i class="checkbox mar-r2 wh06"
                    :class="{checked : checked}"
                    @click="checked = !checked"
                /> 我已阅读和同意
                <span @click="showAgreement = true" class="inline">
                    《平台用户规则》
                </span>
            </p>
            <button class="w-100 btn fw mar-t" @click.prevent="checkRegisterSubmit" >提交信息</button>
        </form>

        <van-popup v-model="showAgreement" style="width:80%; height: 80%" round>
            <div class="pad" style="padding-bottom:0">
                <agreement></agreement>
                <button class="btn btm" @click="showAgreement = false">关闭</button>
            </div>
        </van-popup>

    </div>
</template>

<script>
import { Uploader,Field,  Switch, Icon, Popup,
     DatetimePicker,Dialog} from 'vant';
import agreement from '@/components/Agreement'
import global from "@/common/global"


export default {
    data(){
        return{
            checked : false,
            showAgreement: false,
            org:{
                departName:'',
                usccCode: '',
                legalPerson: '',
                mobile: '',
                busiPic:'',
                idPicFront: '',
                idPicBack: '',
            },
            mobileReg: /^1[3|4|5|7|8|9]\d{9}$/,//手机号正则表达式
			//只保留18位校验，去掉15位的组织机构代码校验
            // ucReg:/^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/,//统一社会信用代码正则表达式
			ucReg:/^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10})$/,//统一社会信用代码正则表达式
            fileList1 : [],
            fileList2 : [],
            fileList3 : [],
        }
    },
    components : {
        [Icon.name]:Icon,
        [Field.name]:Field,
        [Popup.name]:Popup,
        [Switch.name]:Switch,
        [Uploader.name]:Uploader,
        [DatetimePicker.name]:DatetimePicker,
        agreement,
        [Dialog.name]:Dialog,
    },

    beforeMount(){
        this.getNickName()
    },

    methods:{

        /**
         * @Description: 根据openid获取用户信息
         * @Author: Niklaus
         * @Date: 2021-03-16 11:04:40
         */
        getNickName(){
            this.$ajax({
                url: '/gateway/integral/mobile/customer/getNickName',
                method: 'get',
                params: {
                    openid: this.$ls.get('openid'),
                },
            }).then( res => {
                console.log(res.data.result);
                if(res.data.success){
                    if(!res.data.result.name)
                        this.updateNickName(res.data.result.id)
                }
            })
            .catch( err => {
                console.log(err)
            })
        },


        /**
         * @Description: 根据userid,openid 获取nickname并更新
         * @Author: Niklaus
         * @Date: 2021-03-16 11:05:04
         */
        updateNickName(userId){
            let that  = this;
            let openid = this.$ls.get("openid")
            let tokenUrl = "/wxauth/cgi-bin/token?grant_type=client_credential&appid="+global.appid+"&secret="+global.secret;
            this.$ajax.get(tokenUrl).then((res)=>{
                if(res.status=="200"){
                    let token = res.data.access_token;
                    let nicknameUrl = "/wxauth/cgi-bin/user/info?access_token="+token+"&openid="+openid+"&lang=zh_CN";
                    //获取昵称
                    this.$ajax.get(nicknameUrl).then((res)=>{
                        if(res.status=="200"){
                            if(res.data.subscribe=="1"){
                                //写入数据库
                                let data = {};
                                data.id = userId;
                                data.nickname = res.data.nickname;
                                data.unionid = res.data.unionid;
                                data.description ='专家记';
                                this.$ajax({
                                    method: "post",
                                    url: "/gateway/integral/mobile/customer/updateNicknameNew",
                                    params: {
                                        openid:openid,                                        
                                    },
                                    data:data
                                }).then((res)=>{
                                    if(res.status=="200"&&res.data.retCode=="0")
                                        console.log("更新昵称成功");
                                    else
                                        console.log("更新昵称失败！");
                                }).catch(err => {console.log("昵称写入数据库失败")})
                            }else
                                console.log("获取昵称数据失败");
                        }else
                            console.log("请求微信失败");
                    }).catch(err => {console.log("获取昵称请求错误")})
                }else
                    console.log("获取access_token失败");
            }).catch(error => console.log("获取access_token请求错误"))
        },

        //注册提交
        registerSubmit(){
			//获取openid
			let openid = this.$ls.get("openid")
			// if(!openid){
			// 	this.$toast.fail("请使用微信打开！")
			// 	return
			// }
            //公司名称 org.departName
            if(this.org.departName==''){
                this.$toast.fail({
                    message:'请输入公司名称！',
                    className: 'div7'
                });
				return
            }else if(this.org.departName.length>100){
				this.$toast.fail({
				    message:'公司名称过长，请修改！',
				    className: 'div7'
				});
				return
			}
            //统一社会信用代码
            let ucre = new RegExp(this.ucReg)
            if (!ucre.test(this.org.usccCode)) {
                this.$toast.fail({
                    message:'统一社会信用代码不正确！',
                    className: 'div7'
                });
                return
            }
            //联系人姓名 org.legalPerson
            // if(this.org.legalPerson==''){
            //     this.$toast.fail({
            //         message:'请输入联系人姓名！',
            //         className: 'div7'
            //     });
			// 	return
            // }
            //手机号
            // let re = new RegExp(this.mobileReg)
            // if (!re.test(this.org.mobile)) {
            //     this.$toast.fail({
            //         message:'手机号不正确！',
            //         className: 'div7'
            //     });
            //     return
            // }
            //用户协议
            if (!this.checked) {
                this.$toast.fail({
                    message:'请先同意 平台用户规则！',
                    className: 'div7'
                });
                return
            }
            //营业执照1
            // if(this.fileList1.length>0){
            //     this.org.busiPic = this.fileList1[0].path;
            // }else{
            //     this.$toast.fail({
            //         message:'请上传营业执照！',
            //         className: 'div7'
            //     });
            //     return
            // }
            // //法人身份证图片-正面
            // if(this.fileList2.length>0){
            //     this.org.idPicFront = this.fileList2[0].path;
            // }else{
            //     this.$toast.fail({
            //         message:'请上传法人身份证正面图片！',
            //         className: 'div7'
            //     });
            //     return
            // }
            // //法人身份证图片-反面
            // if(this.fileList3.length>0){
            //     this.org.idPicBack = this.fileList3[0].path;
            // }else{
            //     this.$toast.fail({
            //         message:'请上传法人身份证反面图片！',
            //         className: 'div7'
            //     });
            //     return
            // }

            this.org.busiPic = this.fileList1.length > 0 ? this.fileList1[0].path : '';
            this.org.idPicFront = this.fileList2.length > 0 ? this.fileList2[0].path : '';
            this.org.idPicBack = this.fileList3.length > 0 ? this.fileList3[0].path : '';


            let data = {};
            data.departName = this.org.departName
            data.usccCode = this.org.usccCode
            data.legalPerson = this.org.legalPerson
            data.mobile = this.org.mobile
            data.busiPic = this.org.busiPic
            data.idPicFront = this.org.idPicFront
            data.idPicBack = this.org.idPicBack
			data.openid = openid
            this.$ajax({
                method: "post",
                url: "/gateway/ed/external/tbEdSydeEdvasExternal/add",
                // headers: {
                //     "X-Access-Token": this.$ls.get("token"),
                // },
                // params: {
                //     openid: this.$ls.get("openid")
                // },
                data: data
            }).then((res)=>{
                if(res.data.success){
                    this.$toast.success("注册成功")
					this.$router.push({ name: "usercheck" })
                }else{
                    this.$toast.fail(res.data.message)
                }
            });

        },
        //校验图片格式
        beforeRead: function (file) {
            let fileType = ""
            if (file instanceof Array && file.length) {
                for (let i = 0; i < file.length; i++) {
                    fileType = file[i].type.substr(0, file[i].type.indexOf("/"))
                    if (fileType !== "image") {
                        this.$toast("格式错误")
                        return false
                    }
                }
            } else {
                fileType = file.type.substr(0, file.type.indexOf("/"))
                if (fileType !== "image") {
                    this.$toast("格式错误")
                    return false
                }
            }
            return true
        },
        //校验文件大小是否超出限制
        //onOversize:function(file){
        onOversize: function () {
            this.$toast.fail("文件太大")
        },
       //上传图片
        afterRead: function (file) {
            file.status = "uploading"
            file.message = "上传中..."
            let tempFile = []
            let tempthis = this
            //
            //区分单文件上传还是多文件
            if (file instanceof Array && file.length) {
                for (let i = 0; i < file.length; i++) {
                    //formData.append("files", file[i].file)
                    tempFile.push(file[i].file)
                }
            } else {
                //formData.append("files", file.file)
                tempFile.push(file.file)
            }
            //上传
            for (let i = 0; i < tempFile.length; i++) {
                let canvas = document.createElement("canvas")
                let context = canvas.getContext("2d")
                let reader = new FileReader()
                let img = new Image()
                img.onload = function () {
                    let originw = this.width
                    let originh = this.height
                    let maxw = 1200
                    // let maxh = 640;
                    let destw = originw
                    let desth = originh
                    // 若宽小于高，且宽度超过了1200 则以宽度以1200处理;若宽大于高，宽度以1600处理
                    if (originw > originh) maxw = 1600
                    if (originw > maxw) {
                        // if (originw / originh > maxw / maxh) {
                        //     // 更宽，按照宽度限定尺寸
                        //     destw = maxw;
                        //     desth = Math.round(maxw * (originh / originw));
                        // } else {
                        //     desth = maxh;
                        //     destw = Math.round(maxh * (originw / originh));
                        // }
                        destw = maxw
                        desth = Math.round(maxw * (originh / originw))
                    }
                    // canvas对图片进行缩放
                    canvas.width = destw
                    canvas.height = desth
                    // 清除画布
                    context.clearRect(0, 0, destw, desth)
                    // 图片压缩
                    context.drawImage(img, 0, 0, destw, desth)
                    //上传
                    canvas.toBlob(function (blob) {
                        let formData = new FormData()
                        formData.append("files", blob)

                        let openid = tempthis.$ls.get("openid")
                        let accesstoken = tempthis.$ls.get("token")
                        tempthis.$ajax({
                            url:
                                "/gateway/ed/external/tbEdSydeEdvasExternal/uploadPic?openid=" +
                                openid,
                            method: "post",
                            headers: {
                                "X-Access-Token": accesstoken,
                            },
                            data: formData,
                        }).then((res) => {
                            if (res.data.success) {
                                file.path = res.data.message;
                                file.status = ""
                                file.message = ""
                            } else {
                                tempthis.$toast.fail("上传失败")
                            }
                        })
                    }, file.type || "image/jpeg")
                }
                reader.onload = function (e) {
                    img.src = e.target.result
                }
                reader.readAsDataURL(file.file)
            }
        },
        //注册前提交校验
        checkRegisterSubmit(){
            //判断用户是否无效操作员，进行提示
            if(this.$ls.get("invalidOrg")){
                Dialog.confirm({
                    title : '提示',
                    message : '您曾经是：'+this.$ls.get("invalidOrg").departName+'的操作员，如果点【去注册新公司】，将成为新公司的管理员，之前公司的操作员权限将永久失效；如果点【取消】，将取消本次注册操作，如果需要，可以联系之前公司管理员恢复操作员权限。',
                    confirmButtonText : '去注册新公司',
                    cancelButtonText: '取消',
                    }).then(()=>{ //确认
                       this.registerSubmit();
                    }).catch(() =>{//取消
                       return -1;
                    })
            }else{
                this.registerSubmit();
            }
        },
        //下一个方法
      _utf8_encode:function(text){
        text = text.replace(/\r\n/g, "\n");
        var utftext = "";
        for (var n = 0; n < text.length; n++) {
          var c = text.charCodeAt(n);
          if (c < 128) {
            utftext += String.fromCharCode(c);
          } else if ((c > 127) && (c < 2048)) {
            utftext += String.fromCharCode((c >> 6) | 192);
            utftext += String.fromCharCode((c & 63) | 128);
          } else {
            utftext += String.fromCharCode((c >> 12) | 224);
            utftext += String.fromCharCode(((c >> 6) & 63) | 128);
            utftext += String.fromCharCode((c & 63) | 128);
          }
        }
        return utftext;
      },
      _utf8_decode:function(text){
        var string = "";
        var i = 0;
        var c = c1 = c2 = 0;
        while (i < text.length) {
          c = text.charCodeAt(i);
          if (c < 128) {
            string += String.fromCharCode(c);
            i++;
          } else if ((c > 191) && (c < 224)) {
            c2 = text.charCodeAt(i + 1);
            string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
            i += 2;
          } else {
            c2 = text.charCodeAt(i + 1);
            c3 = text.charCodeAt(i + 2);
            string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
            i += 3;
          }
        }
        return string;
      },
      _base64_encode:function(text){
        var _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
        var output = "";
        var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        var i = 0;
        text = this._utf8_encode(text);
        while (i < text.length) {
          chr1 = text.charCodeAt(i++);
          chr2 = text.charCodeAt(i++);
          chr3 = text.charCodeAt(i++);
          enc1 = chr1 >> 2;
          enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
          enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
          enc4 = chr3 & 63;
          if (isNaN(chr2)) {
            enc3 = enc4 = 64;
          } else if (isNaN(chr3)) {
            enc4 = 64;
          }
          output = output +
            _keyStr.charAt(enc1) + _keyStr.charAt(enc2) +
            _keyStr.charAt(enc3) + _keyStr.charAt(enc4);
        }
        return output;
      },
      _base64_decode:function(text){
        var _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
        var output = "";
        var chr1, chr2, chr3;
        var enc1, enc2, enc3, enc4;
        var i = 0;
        text = text.replace(/[^A-Za-z0-9\+\/\=]/g, "");
        while (i < text.length) {
          enc1 = _keyStr.indexOf(text.charAt(i++));
          enc2 = _keyStr.indexOf(text.charAt(i++));
          enc3 = _keyStr.indexOf(text.charAt(i++));
          enc4 = _keyStr.indexOf(text.charAt(i++));
          chr1 = (enc1 << 2) | (enc2 >> 4);
          chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
          chr3 = ((enc3 & 3) << 6) | enc4;
          output = output + String.fromCharCode(chr1);
          if (enc3 != 64) {
            output = output + String.fromCharCode(chr2);
          }
          if (enc4 != 64) {
            output = output + String.fromCharCode(chr3);
          }
        }
        output = this._utf8_decode(output);
        return output;
      },

    },
}
</script>

<style scoped>

form>p{
    margin-top: 0.3rem;
    font-weight: bold;
}
form>input{width:100%; margin-top: 0.3rem;}
form>div{margin-top: 0.3rem;}
.van-uploader__input-wrapper{text-align: center;}
.btm{position: sticky; bottom: 0; width: 50%;margin-left: 25%; margin-top: 0.3rem;}
.red{display: inline-block; margin-left: 0.1rem; vertical-align: sub;}
.inline{color: #3498db;}
</style>
