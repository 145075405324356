<!--
 * @Descripttion: 服务协议
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2021-03-10 16:44:06
-->
<template>
  <div>
    <h3 class="f45">嗨微客专家记服务协议</h3>
    <p>欢迎您使用嗨微客专家记（以下简称“本平台”）。在使用本平台之前，请您认真阅读和理解《嗨微客专家记服务协议》（以下简称“本协议”）的全部内容。</p>
    <h3>一、总则</h3>
    <p>
        1、本平台使用者（以下简称“用户”）注册登录本平台或者使用本平台进行任何行为，即表示用户已知悉、完全理解并同意遵守本协议的全部内容。
    </p>
   <p>
       2、本平台保留随时修改本协议的权利，并对修改情况予以公示，用户在使用本平台时应关注并遵守。若用户不同意修改的内容或不同意本平台随时修改本协议，用户有权不使用本平台提供的服务。
    </p>
   <p>3、用户应当对以其账号进行的所有活动和事件承担全部责任。</p>

    <h3>二、服务内容</h3>
    <p>
        4、本平台根据实际情况向用户提供专家库管理、项目管理、专家抽取及其他相关服务，并根据有关规定保护用户的个人信息。
    </p>
    <p>
        5、用户理解本平台仅按照本协议的约定提供服务,除此之外的因使用本平台产生的第三方服务费用（如通信费、流量费、上网费等）由用户自行负担。
    </p>

    <h3>三、信息安全</h3>
    <p>
        6、用户应当对自己注册的账号和密码的安全承担全部责任，不得将其透露、转让、出借给他人。因用户的保管疏忽或黑客行为导致账号、密码遭他人非法使用，本平台不承担相应责任。
    </p>

    <h3>四、免责声明</h3>
    <p>
        7、本平台将尽一切合理努力保护获得的用户个人信息，但当以下情况发生时，本平台不承担相应责任：
    </p>
    <p class="two">（1）因用户注册信息不真实带来的问题和损失；</p>
    <p class="two">（2）因用户行为造成的用户个人信息泄露造成的损失；</p>
    <p class="two">（3）如遇不可抗力或本平台不能控制的其他事件，本平台不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。不可抗力或本平台不能控制的其他事件包括但不限于地震、台风、洪水、火灾、战争、罢工、政府禁令或政府管制、黑客攻击、电信通信管理部门因技术调整导致断网、病毒侵袭意外原因及电力部门停电、电信网络中断等。
    </p>
    <p class="two">
        （4）用户通过使用本平台提供的服务而获得的任何由第三方制作或提供之内容，本平台对其合法性、准确性、真实性、适用性、安全性等概不负责，亦不承担任何法律责任。因使用或依赖第三方提供内容或服务所产生的损失或损害，本平台也不负担任何责任。
    </p>
    <h3>五、通知与送达</h3>
    <p>
        8、本协议项下本平台对于用户所有的通知均可通过本平台网页公告、微信公众号群发信息、电子邮件、手机短信、服务器推送、常规信件传送或其他常用通讯方式进行，本平台可任意选择其中一种方式对用户发出通知，该通知发送之时视为已送达用户。
        用户有任何事宜需通知本平台时，应当通过本平台对外正式公布的电话、通信地址、电子邮件等联系方式进行联系。
    </p>

  </div>
</template>

<script>
export default {}
</script>

<style scoped>
 h3{text-align: center;padding: 0.3rem 0;}
 p{text-indent: 1em;line-height: 0.8rem;}
 .two{text-indent: 1.5em}
</style>
